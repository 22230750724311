<script setup>
import { ref } from 'vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import SocialIcons from '@/Shared/Components/SocialIcons/SocialIcons.vue'
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  TransitionRoot,
  Dialog,
  TransitionChild,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'

defineProps({
  items: Array,
})

let openMobileNavigation = ref(false)
</script>

<template>
  <TransitionRoot
    as="template"
    :show="openMobileNavigation"
  >
    <Dialog
      as="div"
      class="relative z-40 lg:hidden"
      @close="openMobileNavigation = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
            <div class="flex px-4 pb-2 pt-5 justify-between">
              <div class="">
                <img
                  src="/images/mobile-logo.png"
                  srcset="/images/mobile-logo@2x.png 2x"
                  alt="Image of the Travel Essex Journey Planner App"
                />
              </div>
              <button
                type="button"
                class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                @click="openMobileNavigation = false"
              >
                <span class="sr-only">Close menu</span>
                <XMarkIcon
                  class="h-6 w-6"
                  aria-hidden="true"
                />
              </button>
            </div>

            <!-- Links -->

            <div class="py-4 px-4">
              <p class="text-sm mt-2">
                <AppLink
                  v-if="loggedIn && isSecureRoute()"
                  :href="route('statamic.site')"
                  secondary
                >
                  Visit TravelEssex
                </AppLink>

                <AppLink
                  v-else
                  :href="route('tp.register.welcome')"
                  secondary
                >
                  For transport operators
                </AppLink>
              </p>
            </div>
            <Disclosure
              v-for="item in items"
              :key="item.title"
              v-slot="{ open: childOpen }"
            >
              <DisclosureButton
                class="flex w-full justify-between px-4 py-2 text-left text-sm text-base font-medium hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
              >
                <span>{{ item.title }}</span>
                <ChevronDownIcon
                  :class="childOpen ? 'rotate-180 transform' : ''"
                  class="h-5 w-5 text-black"
                />
              </DisclosureButton>
              <DisclosurePanel class="px-4 pb-6 text-sm text-gray-500">
                <Disclosure
                  v-for="childItem in item.children"
                  :key="childItem.title"
                  v-slot="{ open: grandChildOpen }"
                >
                  <DisclosureButton
                    class="flex w-full justify-between px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                  >
                    <span>{{ childItem.title }}</span>
                    <ChevronDownIcon
                      :class="grandChildOpen ? 'rotate-180 transform' : ''"
                      class="h-5 w-5 text-black"
                    />
                  </DisclosureButton>
                  <DisclosurePanel class="px-4 pb-6 text-sm text-gray-500">
                    <ul
                      v-for="grandChildItem in childItem.children"
                      :key="grandChildItem.title"
                      class=""
                    >
                      <li class="mt-2">
                        <AppLink :href="grandChildItem.url">
                          {{ grandChildItem.title }}
                        </AppLink>
                      </li>
                    </ul>
                  </DisclosurePanel>
                  <hr class="h-px my-0 bg-gray-200 border-0" />
                </Disclosure>
              </DisclosurePanel>
              <hr class="h-px my-0 bg-gray-200 border-0" />
            </Disclosure>

            <div class="border-t border-gray-200 px-4 py-6">
              <p class="block py-2 font-medium text-lg text-gray-900">Contact</p>
              <p class="block py-2 mt-0 font-medium text-md text-gray-900">Having problems using this service?</p>

              <p class="block py-2 mt-0 font-medium text-sm text-gray-900">
                Email
                <AppLink href="mailto:TravelEssex@essex.gov.uk">Essex County Council</AppLink>
              </p>
              <p class="block mt-0 font-medium text-sm text-gray-900">
                Call
                <AppLink href="tel:0800 234 3456">0800 234 3456</AppLink>
              </p>
              <SocialIcons class="mt-6 gap-x-4" />
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <div
    class="cursor-pointer"
    @click="openMobileNavigation = true"
  >
    <img
      src="/images/menu.png"
      srcset="/images/menu@2x.png 2x"
      alt="Image of the mobile menu icon"
      class="lg:hidden"
    />
  </div>

  <PopoverGroup class="flex max-lg:hidden">
    <Popover
      v-for="item in items"
      :key="item.title"
      v-slot="{ open }"
      class="flex"
    >
      <PopoverButton
        :class="open ? '' : 'text-opacity-90'"
        class="group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-black hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-opacity-75"
      >
        {{ item.title }}
        <ChevronDownIcon
          :class="open ? '' : 'text-opacity-70'"
          class="ml-2 h-5 w-5 text-black transition duration-150 ease-in-out group-hover:text-opacity-80"
          aria-hidden="true"
        />
        <span class="sr-only">{{ open ? 'Open' : 'Close' }} {{ item.title }}</span>
      </PopoverButton>

      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <PopoverPanel class="bg-[#ECF1F5] absolute inset-x-0 shadow-md top-full z-10">
          <div class="relative">
            <div class="mx-auto max-w-7xl px-8">
              <div class="flex flex-row py-4">
                <div class="basis-3/4 grid gap-x-8 sm:grid-cols-3">
                  <div
                    v-for="childItem in item.children"
                    :key="childItem.title"
                    class="py-2"
                  >
                    <h3 class="font-bold pb-3">{{ childItem.title }}</h3>
                    <ul
                      v-for="grandChildItem in childItem.children"
                      :key="grandChildItem.title"
                      class=""
                    >
                      <li class="mt-2">
                        <AppLink :href="grandChildItem.url">
                          {{ grandChildItem.title }}
                        </AppLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="basis-1/4 border-l border-l-gray-300 py-2 px-4 min-h-full">
                  <h3 class="font-bold">Contact</h3>
                  <p class="mt-4 font-bold">Having problems using this service?</p>
                  <p class="mt-4">
                    Email
                    <AppLink
                      secondary
                      href="mailto:Passenger.Transport@essex.gov.uk"
                    >
                      Essex County Council
                    </AppLink>
                  </p>
                  <p class="mt-2">
                    Call
                    <AppLink
                      secondary
                      href="tel:0345 603 7631"
                    >
                      0345 603 7631
                    </AppLink>
                  </p>
                  <SocialIcons class="mt-6 gap-x-4" />
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </PopoverGroup>
</template>
