<script setup>
import BusStopMarkedMarkerIcon from '@svgs/map_marker_icons/bus-stop-marked-marker-icon.svg'
import BusStopUnmarkedMarkerIcon from '@svgs/map_marker_icons/bus-stop-unmarked-marker-icon.svg'
import BusStopMarkerIcon from '@svgs/bus-stop-marker-icon.svg'

defineProps({
  markerType: String,
})

const mapMarker = {
  'bus-stop-marked': BusStopMarkerIcon,
  'bus-stop-unmarked': BusStopMarkerIcon,
}
</script>

<template>
  <component :is="mapMarker[markerType]" />
</template>
