<script setup>
import { computed } from 'vue'
import { Switch } from '@headlessui/vue'

const emit = defineEmits(['update:checked'])

const props = defineProps({
  checked: {
    type: [Array, Boolean, Function],
    default: false,
  },
  checkedClass: {
    type: String,
    default: 'bg-black',
  },
})

const proxyChecked = computed({
  get() {
    return props.checked
  },

  set(val) {
    emit('update:checked', val)
  },
})
</script>

<template>
  <Switch
    v-model="proxyChecked"
    :class="proxyChecked ? checkedClass : 'bg-gray-200'"
    class="relative inline-flex h-6 w-11 items-center rounded-full focus:outline-none focus:border-black focus:border-2 focus:ring-2 focus:ring-yellow-500"
  >
    <span
      :class="proxyChecked ? 'translate-x-6' : 'translate-x-1'"
      class="inline-block h-4 w-4 transform rounded-full bg-white transition"
    />
  </Switch>
</template>
