export function useExtendBounds (bounds, percentage) {
  
  const center = bounds.getCenter()

  const northEast = bounds.getNorthEast()
  const southWest = bounds.getSouthWest()

  const latSpan = northEast.lat() - southWest.lat()
  const lngSpan = northEast.lng() - southWest.lng()

  const latExtension = (latSpan * percentage) / 100 / 2
  const lngExtension = (lngSpan * percentage) / 100 / 2

  const newNorthEast = new google.maps.LatLng(
    northEast.lat() + latExtension,
    northEast.lng() + lngExtension
  )
  const newSouthWest = new google.maps.LatLng(
    southWest.lat() - latExtension,
    southWest.lng() - lngExtension
  )

  // Return extended bounds
  return new google.maps.LatLngBounds(newSouthWest, newNorthEast)
}