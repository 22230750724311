<script setup>
import XIcon from '@svgs/x-icon.svg'

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>

<template>
  <div class="px-2 py-1">
    <div class="text-lg justify-between flex items-center">
      <slot name="title" />
      <button
        @click="close"
        class="btn ml-5"
      >
        <XIcon />
      </button>
    </div>

    <div>
      <slot name="titleInfo"></slot>
    </div>

    <div class="mt-2">
      <slot name="content" />
    </div>

    <div
      v-if="$slots.footer"
      class="flex flex-row justify-end"
    >
      <slot name="footer" />
    </div>
  </div>
</template>
