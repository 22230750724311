<script setup>
import BaseCard from './BaseCard.vue'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import { computed } from 'vue'

const props = defineProps({
  data: Object,
})

const busStopLink = computed(() => {
  if (props.data.type === 'park-and-ride') {
    return props.data.url
  } else {
    return `/stop-details/${props.data.stop_id}`
  }
})

const emit = defineEmits(['close'])
</script>

<template>
  <BaseCard @close="$emit('close')">
    <template #title>
      <h3 class="font-bold">{{ data.name }}</h3>
    </template>

    <template #titleInfo>
      <p class="opacity-70">{{ data.is_marked ? '' : '(Unmarked)' }}</p>
    </template>

    <!--  EXTRACT THE COMMENTED OUT BELOW TO A COMPONENT -->

    <!-- <template #content>
      <div class="my-2">
        <p class="opacity-70">Next services at this stop</p>
      </div>
      <div
        v-for="service in data.nextServices"
        class="flex justify-between pb-1"
      >
        <div class="flex items-center">
          <p class="text-center bg-[#103A44] min-w-[50px] text-white font-bold text-base px-2 py-1 mr-4 rounded-md">
            {{ service.service_id }}
          </p>
          <p class="text-base">to {{ service.service_short_name }}</p>
        </div>
        <div class="text-right">
          <p class="text-base">{{ service.departure_time }}</p>
          <p
            class="text-sm"
            :class="service.onTime ? 'text-green' : 'text-red font-normal'"
          >
            {{ service.onTime ? 'On time' : 'Exp ' + service.expected_at }}
          </p>
        </div>
      </div>
    </template> -->

    <template #footer>
      <PrimaryButton
        as="a"
        :href="busStopLink"
        @click="$emit('close')"
        class="btn btn-primary w-full justify-center mt-4"
      >
        View details
      </PrimaryButton>
    </template>
  </BaseCard>
</template>
