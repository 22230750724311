<script setup>
import { ref, watch } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import CurrentLocationMarkerIcon from '@svgs/current-location-marker-icon.svg'
import { GoogleMap, CustomMarker, Polyline } from 'vue3-google-map'
import BusLiveBearingMarkerIcon from '@svgs/bus-live-bearing-marker-icon.svg'
import BusLiveMarkerIcon from '@svgs/bus-live-marker-icon.svg'
import gtfsApi from '@/Shared/Services/gtfs.api.js'
import { useExtendBounds } from '@/Shared/Composables/useExtendBounds.js'

const props = defineProps({
  routeShortName: [Number, String],
  routePolylines: Object,
})

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['geometry', 'places'],
})
const map = ref(null)
const apiPromise = loader.load()

const decodedPolylines = ref([])
const liveMarkers = ref([])
const loadLiveMarkers = ref(true)
const mapBounds = ref(null)
const searchParams = new URLSearchParams(window.location.search)
const userPosition = ref(null)
// const usersLocation = ref(null)

if (searchParams.get('lat') != null) {
  userPosition.value = {
    lat: searchParams.get('lat'),
    lng: searchParams.get('lng'),
  }
}

apiPromise.then((google) => {
  mapBounds.value = new google.maps.LatLngBounds()

  props.routePolylines.unique.forEach((polyline) => {
    const decodedPolyline = google.maps.geometry.encoding.decodePath(polyline)

    for (var i = 0; i < decodedPolyline.length; i++) {
      mapBounds.value.extend(decodedPolyline[i])
    }

    decodedPolylines.value.push(decodedPolyline)
  })

  /* Extend the bounds by percentage */
  mapBounds.value = useExtendBounds(mapBounds.value, 50)

  // navigator.geolocation.getCurrentPosition((position) => {
  //   if (position.coords.latitude && position.coords.longitude) {
  //     usersLocation.value = { lat: position.coords.latitude, lng: position.coords.longitude }
  //   }
  // })
})

const onCenterChange = () => {
  if (loadLiveMarkers.value === true) {
    const newCenter = map.value.map.getCenter()

    gtfsApi.liveUpdatesNearest(newCenter.lat(), newCenter.lng(), 5, props.routeShortName).then((response) => {
      if (response.status === 200) {
        loadLiveMarkers.value = false
        liveMarkers.value = response.data
      }
    })
  }
}

watch(
  () => map.value?.ready,
  (ready) => {
    if (!ready) return
    map.value.map.fitBounds(mapBounds.value)
  }
)
</script>

<template>
  <GoogleMap
    v-if="mapBounds"
    ref="map"
    :api-promise="apiPromise"
    class="w-full h-[500px] my-4"
    :restriction="{
      latLngBounds: mapBounds,
      strictBounds: false,
    }"
    :styles="[
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ]"
    @center_changed="onCenterChange"
  >
    <CustomMarker
      v-if="userPosition"
      :options="{ position: { lat: parseFloat(userPosition.lat), lng: parseFloat(userPosition.lng) } }"
    >
      <CurrentLocationMarkerIcon />
    </CustomMarker>
    <Polyline
      v-for="(decodedPolyline, index) in decodedPolylines"
      :key="index"
      :options="{
        path: decodedPolyline,
        geodesic: true,
        strokeColor: '#E97135',
        strokeOpacity: 1.0,
        strokeWeight: 4,
        zIndex: 1,
      }"
    />
    <CustomMarker
      v-for="liveMarker in liveMarkers"
      :key="liveMarker.vehicle_id"
      :options="{
        position: { lat: parseFloat(liveMarker.position_latitude), lng: parseFloat(liveMarker.position_longitude) },
      }"
    >
      <div class="relative">
        <BusLiveMarkerIcon />
        <div
          class="absolute top-1 left-16 -z-10 bg-[#1D594C] py-1 pl-6 pr-4 rounded-r-xl text-base font-bold text-white text-center"
        >
          {{ liveMarker.route_short_name }}
        </div>
        <BusLiveBearingMarkerIcon
          class="absolute left-1/2 bus-live-marker-icon"
          :style="`--rotation: ${liveMarker.position_bearing}deg;`"
        />
      </div>
    </CustomMarker>
  </GoogleMap>
</template>
