<script setup>
import { computed } from 'vue'
import BusStopMarkerCard from './BusStopMarkerCard.vue'
// import LiveBusMarkerCard from './LiveBusMarkerCard.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  marker: Object,
})

const emit = defineEmits(['close'])

const modalComponent = computed(() => {
  switch (props.marker.type) {
    case 'bus-stop-marked':
    case 'bus-stop-unmarked':
      return BusStopMarkerCard
    default:
      return null
  }
})
</script>

<template>
  <component
    :is="modalComponent"
    :show="show"
    :data="props.marker.data"
    @close="$emit('close')"
  />
</template>
